<template>
  <div class="theme">
    <div v-for="(color, index) in theme" :key="index">
      <div class="theme__color" :style="'background:' + color"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Theme",
  props: {
    theme: Object,
  },
  data() {
    return {
      selectedTheme: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.theme {
  display: flex;
  flex-direction: row;
  height: 150px;
  cursor: pointer;
  &__color {
    width: 45px;
    height: 100%;
  }
}
</style>
