<template>
  <div class="branding">
    <div class="branding__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="branding-theme-selector">
      <div class="theme-container" v-for="(item, index) in pallettesList" :key="index">
        <template v-if="item.name !== 'default'">
          <b-form-radio v-model="chosenTheme" :value="item.name" :id="index.toString()" :title="'Choose ' + item.name"
            @change="previewTheme(item.name)" />
          <div class="theme__title">Theme {{ index + 1 }}</div>
          <div class="theme__body" @click="goToThemeMaker(item.name)">
            <Theme :theme="item.palette" />
          </div>
        </template>
      </div>
    </div>
    <div class="save-button">
      <button v-loading="updatingChosenTheme" @click="updateChosenTheme()" class="save-button__button"
        v-if="!currentAndNewThemeAreEqual">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { changeTheme, getThemeVariables } from "../../../utils/Themes";
import Theme from "./Theme.vue";

export default {
  name: "Branding",
  components: {
    Theme,
  },
  data() {
    return {
      isMagayaUser: false,
      settingsThemes: null,
      themesList: [],
      pallettesList: [],
      chosenTheme: "",
      defaultChosenTheme: null,
      currentAndNewThemeAreEqual: true,
      updatingChosenTheme: false,
    };
  },
  watch: {
    chosenTheme() {
      this.currentAndNewThemeAreEqual = this.chosenTheme === this.defaultChosenTheme;
    },
  },
  created() {
    this.themesList = this.$store.getters["color/getThemes"];
    this.chosenTheme = this.$store.getters["color/getChosenTheme"];
    this.defaultChosenTheme = this.chosenTheme;
    this.pallettesList = this.themesList.map((theme) => this.getThemePalette(theme));
    this.isMagayaUser = this.$store.getters["login/getTokenInfo"].magaya_guid;
  },
  beforeDestroy() {
    if (!this.currentAndNewThemeAreEqual) {
      const theme = this.themesList.find((item) => item.name === this.defaultChosenTheme);
      changeTheme(getThemeVariables(theme));
    }
  },
  methods: {
    getThemePalette(theme) {
      const palette = {
        first: this.findVariableInTheme(theme.variables, "--color-primary-company"),
        second: this.findVariableInTheme(theme.variables, "--color-sidebar-option-hover"),
        third: this.findVariableInTheme(theme.variables, "--color-primary-trigger-button"),
        fourth: this.findVariableInTheme(theme.variables, "--color-primary-trigger-button-hover"),
        fifth: this.findVariableInTheme(theme.variables, "--color-sidebar-shadow"),
      };
      return { name: theme.name, palette };
    },
    findVariableInTheme(variables, variableName) {
      let foundVariable = null;
      const variablesKeys = Object.keys(variables);
      for (let i = 0; i < variablesKeys.length; i += 1) {
        foundVariable = variables[variablesKeys[i]].find(
          (variable) => variable.variable_name === variableName
        );
        if (foundVariable) {
          break;
        }
      }
      return foundVariable.color;
    },
    previewTheme(themeName) {
      const theme = this.themesList.find((item) => item.name === themeName);
      changeTheme(getThemeVariables(theme));
    },
    async updateChosenTheme() {
      if (this.$options.filters.checkPermission("patch:account-settings")) {
        this.updatingChosenTheme = true;
        const id = this.$store.getters["login/getTokenInfo"].account_id;
        await this.$store.dispatch("account/updateAccountSettings", {
          id: { account_id: id },
          settings: { chosen_theme: this.chosenTheme },
        });
        this.$store.commit("color/setChosenTheme", this.chosenTheme);
        const settings = JSON.parse(localStorage.getItem("user-settings"));
        settings.chosen_theme = this.chosenTheme;
        localStorage.setItem("user-settings", JSON.stringify(settings));
        this.defaultChosenTheme = this.chosenTheme;
        this.currentAndNewThemeAreEqual = true;
        this.updatingChosenTheme = false;
        this.$store.commit("setRenderSidebarLogo");
      }
    },
    goToThemeMaker(themeName) {
      this.$router.push({
        name: "ThemeMaker",
        params: { theme: themeName },
      });
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.branding {
  @include splitted-view-styles;
  padding-top: 2%;
  padding-bottom: 20px;

  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;

    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  &-theme-selector {
    padding: 15px 5px;
    display: flex;
    flex-wrap: wrap;
  }
}

.theme {
  &-container {
    margin: 10px 20px;
  }

  &__title {
    text-align: start;
    margin-bottom: 5px;
  }
}

.theme-container {
  position: relative;
}

.save-button {
  &__button {
    margin: 25px auto 0px auto;
    display: flex;
    justify-content: space-around;
    @include secondary-button;
    background-color: $color-save-button;
    color: $color-white;
    border: none;

    &:hover {
      background-color: $color-primary-trigger-button-hover;
    }

    width: 20%;
    text-align: center;
    padding: 7px;
  }
}

::v-deep {

  // .custom-control-label::after  {
  //   top: 0.1rem;
  // }
  & .custom-control {
    position: absolute;
    right: -8px;
    top: 2px;
  }

  & .custom-control-input:checked~.custom-control-label::before {
    background-color: $color-primary-trigger-button;
    border-color: $color-border-container;
    // padding: 9px;
  }

  // & .custom-control-label::before {
  //   border: 1px solid $color-border-container;
  // }
  // & .custom-checkbox .custom-control-label::before {
  //   // padding: 9px;
  // }
  // & .custom-control-label::before {
  //   // width: 19px;
  //   // height: 19px;
  // }
  // & .custom-control-label::after {
  //   // width: 5px;
  //   // height: 5px;
  //   // top: 0.22rem;
  //   // left: -1.46rem;
  //   // padding: 10px;
  // }
}
</style>
